import PropTypes from 'prop-types';
import React, { useState } from 'react';
import InterstitialSchoolDropdown from './interstitialSchoolDropdown';
import InterstitialSubjectDropdown from './interstitialSubjectDropdown';
import InterstitialSectionDropdown from './interstitialSectionDropdown';
import InterstitialStudentComponent from './interstitialStudentComponent';
import { NO_DATA_ERROR_MESSAGE, NO_DATA_SUBJECT_ERROR_MESSAGE, NO_DATA_SECTION_ERROR_MESSAGE } from './../../../../constants/reports';
import ReportError from './../../../ap-osre-error-handling/ReportError';

import { Button, YellowButton } from '@cb/apricot-react';

const InterstitialPage = ({
  reportName,
  selectedYear,
  sttVal,
  setSelectedOrgId,
  setSelectedSubjectId,
  setSelectedSubjectName,
  setSelectedAICaption,
  showInterstitialPage,
  isReportError,
  setIsReportError,
  hasRun,
  setShowInterstitialPage,
  interstitialSchoolDropdown,
  interstitialSubjectDropdown,
  interstitialSectionDropdown,
  interstitialStudentComponent,
  onRunReport,
  preSelectedSubject,
  setSelectedSectionIds,
  setSelectedSectionNames,
  setSelectedStudentId,
  selectedStudentId,
  setSelectedStudentName,
  isDistrictAdmin,
  isAggregate,
  defaultValue,
  selectedOrgId
}) => {

  // non-report specific conditions for showing the interstitial page
  // currently just one condition, if there is a report error, we will not show interstitial page
  const genericShowInterstitialPage = !isReportError;

  // tracks variables that are set in the specific dropdowns
  const [schoolDropdownAICaption, setSchoolDropdownAICaption] = useState('');
  const [schoolDropdownSelectedId, setSchoolDropdownSelectedId] = useState('');
  const [subjectDropdownSelectedId, setSubjectDropdownSelectedId] = useState('');
  const [sectionDropdownSelectedId, setSectionDropdownSelectedId] = useState('');
  const [studentListSelectedId, setStudentListSelectedId] = useState('');
  const [subjectDropdownSelectedName, setSubjectDropdownSelectedName] = useState('');
  const [sectionDropdownSelectedNames, setSectionDropdownSelectedNames] = useState('');
  const [studentListSelectedName, setStudentListSelectedName] = useState('');
  const [noDataError, setNoDataError] = useState('');
  const [studentFilter, setStudentFilter] = useState(false);

  // handles the cancel button being pressed, returns to the already loaded report
  const handleCancel = () => {
    setShowInterstitialPage(false);
    if (interstitialStudentComponent) {
      setStudentFilter(false);
    }
  };

  // handles run report being clicked
  const loadRunReport = () => {
    if (interstitialSchoolDropdown) {
      setSelectedOrgId(schoolDropdownSelectedId);
      setSelectedAICaption(schoolDropdownAICaption);
    }
    if (interstitialSubjectDropdown) {
      setSelectedSubjectId(subjectDropdownSelectedId);
      setSelectedSubjectName(subjectDropdownSelectedName);
    }
    if (interstitialSectionDropdown) {
      setSelectedSectionIds(sectionDropdownSelectedId);
      setSelectedSectionNames(sectionDropdownSelectedNames);
    }
    if (interstitialStudentComponent) {
      setSelectedStudentId(studentListSelectedId);
      setSelectedStudentName(studentListSelectedName);
      setStudentFilter(false);
    }

    setShowInterstitialPage(false);

    onRunReport(schoolDropdownSelectedId, subjectDropdownSelectedId, sectionDropdownSelectedId, studentListSelectedId);
  };

  // determines whether or not run report button should be enabled
  const isRunReportEnabled = () => {
    return (
      (!interstitialSchoolDropdown || (interstitialSchoolDropdown && schoolDropdownSelectedId !== '')) &&
      (!interstitialSubjectDropdown || (interstitialSubjectDropdown && subjectDropdownSelectedId !== '')) &&
      (!interstitialSectionDropdown || (interstitialSectionDropdown && sectionDropdownSelectedId.length > 0)) &&
      (!interstitialStudentComponent || (interstitialStudentComponent && studentListSelectedId !== ''))
    );
  };

  const commonDropdownProps = {
    reportName,
    sttVal,
    selectedYear,
    showInterstitialPage,
    setIsReportError,
    isVisible: showInterstitialPage,
    isDistrictAdmin,
    isAggregate,
    setNoDataError
  };

  return (
    <>
      {genericShowInterstitialPage && interstitialSchoolDropdown && showInterstitialPage && noDataError === 'selectSchool' &&
        <ReportError message={NO_DATA_ERROR_MESSAGE} />}
      {genericShowInterstitialPage && interstitialSchoolDropdown && (
        <InterstitialSchoolDropdown
          {...commonDropdownProps}
          label={'1. School'}
          setSchoolDropdownSelectedId={setSchoolDropdownSelectedId}
          setSchoolDropdownAICaption={setSchoolDropdownAICaption}
          schoolDropdownSelectedId={schoolDropdownSelectedId}
          defaultValue={defaultValue?.orgId}
        />
      )}

      {genericShowInterstitialPage && showInterstitialPage && interstitialSchoolDropdown && interstitialSubjectDropdown && noDataError === 'selectSubject'
        ? <ReportError message={NO_DATA_SUBJECT_ERROR_MESSAGE} /> : !interstitialSchoolDropdown &&
        interstitialSubjectDropdown && showInterstitialPage && noDataError === 'selectSubject'
          ? <ReportError message={NO_DATA_ERROR_MESSAGE} /> : null}

      {genericShowInterstitialPage && interstitialSubjectDropdown &&
      // if there is a pre-selected subject, ensure it is selected in subject dropdown
        <InterstitialSubjectDropdown
          {...commonDropdownProps}
          label={`${1 + (interstitialSchoolDropdown ? 1 : 0)}. Subject`}
          preSelectedSubject={preSelectedSubject}
          setSubjectDropdownSelectedId={setSubjectDropdownSelectedId}
          setSubjectDropdownSelectedName={setSubjectDropdownSelectedName}
          schoolDropdownSelectedId={schoolDropdownSelectedId}
          optionsOverride={preSelectedSubject ? [preSelectedSubject] : undefined}
          hasSchoolDropdown={interstitialSchoolDropdown}
          setSelectedAICaption={setSelectedAICaption}
        />
      }

      {genericShowInterstitialPage && showInterstitialPage && interstitialSubjectDropdown && interstitialSectionDropdown &&
      noDataError === 'selectSection' && <ReportError message={NO_DATA_SECTION_ERROR_MESSAGE} />}
      {genericShowInterstitialPage && interstitialSectionDropdown &&
        <InterstitialSectionDropdown
          {...commonDropdownProps}
          label={`${2 + (interstitialSchoolDropdown ? 1 : 0)}. Sections`}
          setSectionDropdownSelectedId={setSectionDropdownSelectedId}
          setSectionDropdownSelectedNames={setSectionDropdownSelectedNames}
          schoolDropdownSelectedId={schoolDropdownSelectedId}
          subjectDropdownSelectedId={subjectDropdownSelectedId}
          noDataError={noDataError}
        />
      }

      {genericShowInterstitialPage && interstitialStudentComponent && showInterstitialPage && noDataError === 'selectStudent' &&
        <ReportError message={NO_DATA_ERROR_MESSAGE} />}
      {genericShowInterstitialPage && interstitialStudentComponent &&
      <InterstitialStudentComponent
        {...commonDropdownProps}
        selectedStudentId={selectedStudentId}
        setStudentListSelectedId={setStudentListSelectedId}
        setStudentListSelectedName={setStudentListSelectedName}
        studentFilter={studentFilter}
        setStudentFilter={setStudentFilter}
      />
      }

      { genericShowInterstitialPage &&
        showInterstitialPage &&
        <div className="continue-button-container container">
          <div className="dropdown-content-container">
            {
              !hasRun ? null
                : <Button className="general-continue-button cb-margin-right-16" onClick={handleCancel} disabled={!hasRun}>
                Cancel
                </Button>
            }
            <YellowButton className="general-continue-button" onClick={loadRunReport} disabled={!isRunReportEnabled()}> Run Report</YellowButton>
          </div>
        </div>
      }
    </>
  );
};

InterstitialPage.propTypes = {
  reportName: PropTypes.string,
  selectedYear: PropTypes.number,
  sttVal: PropTypes.string,
  setSelectedOrgId: PropTypes.func,
  setSelectedSubjectId: PropTypes.func,
  setSelectedSubjectName: PropTypes.func,
  setSelectedAICaption: PropTypes.func,
  showInterstitialPage: PropTypes.bool,
  isReportError: PropTypes.bool,
  setIsReportError: PropTypes.func,
  hasRun: PropTypes.bool,
  setShowInterstitialPage: PropTypes.func,
  interstitialSchoolDropdown: PropTypes.bool,
  interstitialSubjectDropdown: PropTypes.bool,
  interstitialSectionDropdown: PropTypes.bool,
  interstitialStudentComponent: PropTypes.bool,
  onRunReport: PropTypes.func.isRequired,
  preSelectedSubject: PropTypes.string,
  setSelectedSectionIds: PropTypes.func,
  setSelectedSectionNames: PropTypes.func,
  setSelectedStudentId: PropTypes.func,
  selectedStudentId: PropTypes.string,
  setSelectedStudentName: PropTypes.func,
  selectedOrgId: PropTypes.number,
  isDistrictAdmin: PropTypes.bool,
  isAggregate: PropTypes.bool,
  defaultValue: PropTypes.object
};

export default InterstitialPage;
