import React from 'react';
import PropTypes from 'prop-types';
import CongratulationsHeader from '../school/congratulationsHeader';

import DetailTable from '../../common/detailTable';
import { getSchoolHeaderComponent } from './schoolHeader';

const HonorRollCriteria = ({
  overall,
  school,
  year,
  tableData
}) => {

  return (
    <div id='criteriaTabContainer'>
      <CongratulationsHeader school={school} overall={overall} year={year} source='criteria' />

      <div className='cb-margin-top-8'>
        <DetailTable tableId='honor_roll_criteria_table' responsive={true} data={tableData} columns={[{
          title: 'AP School Honor Roll Metrics',
          field: 'category',
          rowHeader: true
        },
        { title: 'Criteria|Bronze', field: 'bronze', className: 'cb-align-center bronzeHeader'},
        { title: 'Criteria|Silver', field: 'silver', className: 'cb-align-center silverHeader'},
        { title: 'Criteria|Gold', field: 'gold', className: 'cb-align-center goldHeader'},
        { title: 'Criteria|Platinum', field: 'platinum', className: 'cb-align-center platinumHeader'},
        { title: 'Your School',
          field: 'schoolPercent',
          className: 'cb-align-center',
          headerComponent: getSchoolHeaderComponent(overall)
        },
        { title: 'Your School',
          field: 'schoolTier',
          className: 'cb-align-center',
          headerComponent: getSchoolHeaderComponent(overall)
        }]} />
      </div>

    </div>);
};

HonorRollCriteria.propTypes = {
  school: PropTypes.string,
  overall: PropTypes.string,
  year: PropTypes.number,
  tableData: PropTypes.array
};

export default HonorRollCriteria;
