import axiosInstance from '../../utils/axiosInstance';
import config from '../../utils/envConfig.js';
import { HONOR_ROLL_URI } from '../../constants/reports';

// returns report list for current request
const getHonorRollData = ({ sttVal, navYear, navSchool, isDistrictAdmin, isAggregate }) => {
  const url = `${config.apiBaseUrl}${config.genesisUri}${HONOR_ROLL_URI}`;
  const params = {
    sttVal,
    navYear
  };

  if (isDistrictAdmin && !isAggregate) {
    params.navSchool = navSchool;
  }

  return axiosInstance.get(url, { params });
};

export default getHonorRollData;
