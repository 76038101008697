import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { ACTIVE_REPORT_LINK_CSS_CLASS, HONOR_ROLL_REPORT_TITLE } from '../../../constants/reports';

const LinkToHonorRollSchoolReport = ({
  year,
  isDistrictAdmin,
  schoolName,
  orgId,
  sttVal,
  setHasRun
}) => (<span>{<NavLink className={ACTIVE_REPORT_LINK_CSS_CLASS} onClick={() => {
  setHasRun(false);
}} to={{
  pathname: `/report/${HONOR_ROLL_REPORT_TITLE}`,
  params: {
    year: year,
    report: HONOR_ROLL_REPORT_TITLE,
    isDistrictAdmin: isDistrictAdmin,
    isAggregate: false,
    schoolName,
    orgId,
    genesisSttVal: sttVal
  }
}}>{schoolName}</NavLink>}</span>
);

LinkToHonorRollSchoolReport.propTypes = {
  year: PropTypes.number,
  isDistrictAdmin: PropTypes.bool,
  isAggregate: PropTypes.bool,
  orgId: PropTypes.number,
  schoolName: PropTypes.string,
  sttVal: PropTypes.string,
  setHasRun: PropTypes.func
};

export default LinkToHonorRollSchoolReport;
