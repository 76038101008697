import { HRORGS_URI, ORGS_URI } from '../constants/reports';
import axiosInstance from '../utils/axiosInstance';
import config from '../utils/envConfig.js';

const getUrl = (reportName) =>
  `${config.apiBaseUrl}${config.genesisUri}${reportName === 'HonorRoll' ? HRORGS_URI : ORGS_URI}`;

// returns list of orgs for a given district and year
const getReportOrgSelect = ({adminYear, reportName, sttVal}) => {
  const url = getUrl(reportName);
  const params = {
    sttVal,
    reportName,
    adminYear
  };

  return axiosInstance.get(url, { params });
};

export default getReportOrgSelect;
