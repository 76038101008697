import React from 'react';
import PropTypes from 'prop-types';
import { APCentralLink } from './summaryTab/tooltips';
import { getNotApplicableNoteText, getAggNotApplicableNoteText, getQualificationNoteTextFirst, getQualificationNoteTextSecond, getViewThresholdsText } from './utils';
import ViewThresholdsModal from './districtAgg/thresholdsModal';

const HonorRollFooter = ({year, showNaMessage, isAggregate = false, criteriaTableData}) => {
  const viewThresholdsModalTrigger = 'openThresholdsModal';

  return (
    <>
      <div className='cb-margin-top-32' id='honor_roll_footer_container'>
        {getQualificationNoteTextFirst(year)}{isAggregate ? <a href='!#' id={viewThresholdsModalTrigger} aria-haspopup="dialog">{getViewThresholdsText()}</a> : ''} {getQualificationNoteTextSecond()}<APCentralLink />.

        {showNaMessage &&
        (<>
          <br />
          <br />
          {isAggregate ? getAggNotApplicableNoteText(year) : getNotApplicableNoteText(year)}
        </>)}
      </div>
      <ViewThresholdsModal year={year} viewThresholdsModalTrigger={viewThresholdsModalTrigger} criteriaTableData={criteriaTableData} />
    </>);
};
HonorRollFooter.propTypes = {
  year: PropTypes.number,
  showNaMessage: PropTypes.bool,
  isAggregate: PropTypes.bool,
  criteriaTableData: PropTypes.array
};

export default HonorRollFooter;
