import { MultiSelect, Select } from '@cb/apricot-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Spinner from '../../../common/loader/spinner';
import { isEmpty } from 'ramda';
import './interstitial.css';

const GenericDropdown = ({
  isDisabled = () => false,
  showInterstitialPage,
  isVisible,
  setIsReportError,
  getOptionsApiCall,
  handleOptionChange,
  queryDataUseEffectConditions = [],
  placeholderText,
  defaultSelectCaption,
  label,
  multiSelect = false,
  id,
  ariaLabel,
  shouldMakeApiCall = () => true,
  setNoDataError,
  defaultValue
}) => {

  // constants for default option
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(0);

  const [isLoadingOptions, setIsLoadingOptions] = useState(false);

  const disabled = isDisabled();
  const [disableSelect, setDisableSelect] = useState(false);

  const resetSelectedValue = () => {
    setSelectedValue(multiSelect ? [] : '');
    handleOptionChange(multiSelect ? [] : '', placeholderText);
  };

  const [selectProps, setSelectProps] = useState({ labelNoValue: placeholderText });

  // when this component first loads, get all available options
  useEffect(() => {
    async function getOptions() {
      if (!shouldMakeApiCall()) {
        return;
      }
      try {
        setIsLoadingOptions(true);
        resetSelectedValue();
        setDisableSelect(false);
        setNoDataError('');

        // getOptionsApiCall
        const optionsResponse = await getOptionsApiCall();

        // @TODO - handle when no data is returned (if sections on IPR, we don't want to show no data error)
        // if (!optionsResponse) {
        //   setIsLoadingOptions(false);
        //   return;
        // }
        let preSelectedVal = {};
        // if no options returned, set no data error
        if (optionsResponse.data.length < 1) {
          setNoDataError(id);
          setDisableSelect(true);
        } else {
          const formattedOptions = optionsResponse.data.map((option, i) => {
            if (defaultSelectCaption && option.caption === defaultSelectCaption) {
              preSelectedVal = {value: i, optionValue: option.value, label: option.caption};
            }
            return { value: i, optionValue: option.value, label: option.caption };
          });
          setOptions(formattedOptions);

          if (defaultValue) {
            const defaultOption = formattedOptions.find(el => el.optionValue === defaultValue);
            setSelectedValue(defaultOption.value);
            setDisableSelect(false);
            handleOptionChange(defaultOption.optionValue, defaultOption.label);
          }

          if (defaultSelectCaption && !isEmpty(preSelectedVal)) {
            setSelectedValue(preSelectedVal.value);
            setDisableSelect(false);
            setSelectProps({});
            handleOptionChange(preSelectedVal.optionValue, preSelectedVal.label);
          }
        }
        setIsLoadingOptions(false);
      } catch (err) {
        setIsLoadingOptions(false);
        setIsReportError(true);
      }
    }
    // only make the call to get options on component load if we are showing this page
    if (showInterstitialPage && !isDisabled()) {
      getOptions();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...queryDataUseEffectConditions]);

  useEffect(() => {
    if (disabled) {
      resetSelectedValue();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  /*
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    On change handlers for drop downs
 */
  const onOptionChange = (e) => {
    if (e === '') {
      setSelectedValue(e);
      handleOptionChange('', placeholderText); // value, label
    } else {
      const selectedItem = options[e];
      setSelectedValue(selectedItem.value);
      handleOptionChange(selectedItem.optionValue, selectedItem.label); // value, label
    }

  };

  const multiSelectOnChange = (selections) => {
    setSelectedValue(selections);
    handleOptionChange(selections);
  };
  /*
      END on change handlers
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  */
  return (
    <div style={{ display: isVisible ? 'block' : 'none' }}>
      <div className="container">
        <div className="dropdown-title cb-gray1-color cb-sans-serif cb-font-size-regular cb-font-weight-bold">{label}</div>
        {
          isLoadingOptions
            ? <Spinner size="md" alignment="left" removePadding={true} />
            : <div className="cb-select dropdown-content-container">
              {
                multiSelect
                  ? <MultiSelect
                    selectId={id}
                    options={options}
                    label={placeholderText}
                    onChange={multiSelectOnChange}
                    value={selectedValue}
                    validation={false}
                    disabled={isDisabled()}
                    selectAll={true}
                  />
                  : <Select disabled={isDisabled() || disableSelect} id={id} name={id} value={selectedValue} onChange={onOptionChange}
                    ariaLabel={ariaLabel} reset={disableSelect}
                    values={options} {...selectProps}
                  />
              }

            </div>
        }
      </div>
    </div>);
};

GenericDropdown.propTypes = {
  showInterstitialPage: PropTypes.bool,
  isVisible: PropTypes.bool,
  setIsReportError: PropTypes.func,
  placeholderText: PropTypes.string,
  defaultSelectCaption: PropTypes.string,
  getOptionsApiCall: PropTypes.func,
  handleOptionChange: PropTypes.func,
  queryDataUseEffectConditions: PropTypes.array,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isDisabled: PropTypes.func,
  multiSelect: PropTypes.bool,
  shouldMakeApiCall: PropTypes.func,
  id: PropTypes.string,
  ariaLabel: PropTypes.string,
  setNoDataError: PropTypes.func,
  defaultValue: PropTypes.number
};

export default GenericDropdown;
