import React, { useEffect, useContext } from 'react';
import analytics from '../../utils/analytics';
import {APP_ID} from '../../constants/appInfo';
import config from './../../utils/envConfig';
import './login.css';
import { AppContext } from '../../AppContext.js';
import createMarkup from '../../utils/createMarkup';
import { FocusedBand, IdentityCard } from '@cb/apricot-react';

const HomeSignedOut = () => {
  useEffect(() => {
    analytics.reportCurrentView('login');
  }, []);

  const appContext = useContext(AppContext);
  const createAccountUrl = `https://account.collegeboard.org/login/signUp?idp=EPL&appId=${APP_ID}&DURL=${config.UIURL}`;
  const topDesc = 'Sign in to your account to get started.';

  return (<>
    <FocusedBand className="cb-blue3-bg"
      imageContainerClass="cb-blue3-bg"
      image={'/assets/homepage_hero1.jpg'}
    >
      <IdentityCard id='identityCard' appId={APP_ID} isStudent={false} destinationUrl={config.UIURL} topDesc={topDesc}
        createAccountUrl={createAccountUrl} skipEnroll='Y' />
    </FocusedBand>
    <div className='container'>
      <div className='row'>
        <div className='col-xs-12 col-sm-6 col-md-7 col-lg-7 offset-xl-0 col-xl-7 offset-2xl-0 col-2xl-7 offset-3xl-1 col-3xl-5'>
          <div className='login-aposre-text'>
            <h2 className='login-header'>AP Score Reports for Educators</h2>
            {
              appContext?.content?.login_page &&
                <div dangerouslySetInnerHTML={createMarkup(appContext.content.login_page)}></div>
            }
          </div>
        </div>
      </div>
    </div>
  </>
  );
};

export default HomeSignedOut;
