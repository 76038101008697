import React from 'react';
import PropTypes from 'prop-types';
import { getClassOfText, getCongratulationsText, isTier } from '../utils';

const HonorRollCongratulationsHeader = ({school, overall, year, source}) => {
  return (

    <div>
      {
      // show congrats message if school achieved at least bronze tier
        isTier(overall)
          ? (<h5>
            {getCongratulationsText({school, overall, year})}
          </h5>)
          : (<h5>{school}</h5>)
      }
      <h5 className='cb-padding-top-16' id={`honor_roll_class_year_id_${source}`}>{getClassOfText(year)}</h5>
    </div>

  );
};
HonorRollCongratulationsHeader.propTypes = {
  school: PropTypes.string,
  overall: PropTypes.string,
  year: PropTypes.number,
  hasTier: PropTypes.bool,
  source: PropTypes.string // just used for id
};

export default HonorRollCongratulationsHeader;
