import getClient, { MAX_APPSYNC_TIMEOUT, TIME_FOR_SUBSCRIPTION_CONNECTION } from '../../../../graphql/client';
import config from '../../../../utils/envConfig';

const DEFAULT_ERROR_PAGE = '/4xx-errors/404-connection-timeout.html';
const NO_DATA_PAGE = '/4xx-errors/404-no-data-error.html';
const NO_DATA_ERROR = 'No Data returned.';

const tabOpener = () => {
  const tab = window.open('about:blank', '_blank');
  tab.opener = null;
  tab.document.write(`
          <link href="https://atlas.collegeboard.org/apricot/prod/4.5.0/main.css" rel="stylesheet">
          <script type="text/javascript">
            const timeout = window.setTimeout('window.location="${config.UIURL}5xx-errors/504-gw-timeout.html"; ',900000);
          </script>
          <div class="container">
            <div id="spinner" class="row" style="justify-content: space-around">
            <div class="cb-padding-top-48">
            <div role="status" aria-labelledby="spinner">
            <div class="cb-spinner" id="spinner2"
              role="img" aria-label="Loading…" data-cb-apricot="spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            </div>
            </div>
            </div>
          </div>`);
  return tab;
};
const redirectTabToPage = (tab, loc) => {
  tab.location = loc;
};

// this function is used for long running reports, taken from initial SDD implementation
const openDownloadInNewTab = ({subscribeParams, queryParams, downloadFile}) => {

  const cb = window.cb;
  let subscription;
  let isSubscribed = true;
  const tab = tabOpener();
  const client = getClient(cb);

  (async () => {
    subscription = client
      .subscribe(subscribeParams)
      .subscribe({
        next: async (data) => {
          isSubscribed = false;
          subscription.unsubscribe();
          // if downloadFile in, run it passing in data then close tab if successful or show error
          if (downloadFile) {
            try {
              await downloadFile(data);
              tab.close();
            } catch (err) {
              redirectTabToPage(tab, DEFAULT_ERROR_PAGE);
            }
          }
        },
        error: _error => {
          isSubscribed = false;
          subscription.unsubscribe();
          redirectTabToPage(tab, DEFAULT_ERROR_PAGE);
          tab.focus();
        }
      });
  })();

  setTimeout(() => {
    client
      .query(queryParams)
      .catch((err) => {
        if (!JSON.stringify(err).includes('Execution timed out')) {
          isSubscribed = false;
          subscription.unsubscribe();
          redirectTabToPage(tab, DEFAULT_ERROR_PAGE);
          tab.focus();
        }
      });

  }, TIME_FOR_SUBSCRIPTION_CONNECTION);

  setTimeout(() => {
    if (isSubscribed) {
      subscription.unsubscribe();
    }
  }, MAX_APPSYNC_TIMEOUT);

};

export const openGetDownloadInNewTab = async ({ callApi, downloadFile }) => {
  const tab = tabOpener();
  if (downloadFile) {
    try {
      const data = await callApi();
      await downloadFile(data);
      tab.close();
    } catch (err) {
      redirectTabToPage(tab, DEFAULT_ERROR_PAGE);
      tab.focus();
    }
  }
};

export default openDownloadInNewTab;
