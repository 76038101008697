import React from 'react';
import PropTypes from 'prop-types';
import SumReportInfoModal from '../../../ap-osre-report/summaryReportModal';
import infoImage from '../../info.png';
import {
  EQUITY_AND_EXCELLENCE_REPORT_TITLE, FIVE_YEAR_SCORE_SUMMARY_REPORT_TITLE, CURRENT_YEAR_SCORE_SUMMARY_REPORT_TITLE, SUMMARY_BY_STUDENT_DEMOGRAPHIC_REPORT_TITLE, DISTRICT_SUMMARY_BY_SCHOOL_REPORT_TITLE, SCHOLAR_SUMMARY_REPORT_TITLE, HONOR_ROLL_REPORT_TITLE, HONOR_ROLL_REPORT_DISPLAY_TITLE
} from '../../../../constants/reports';
import ReportLink from '../reportLink';

const reportMap = {
  'Five Year Score Summary': { reportPath: FIVE_YEAR_SCORE_SUMMARY_REPORT_TITLE, hasSchoolLink: true, hasAggregateLink: false },
  'Equity And Excellence': { reportPath: EQUITY_AND_EXCELLENCE_REPORT_TITLE, hasSchoolLink: true, hasAggregateLink: true },
  'Current Year Score Summary': { reportPath: CURRENT_YEAR_SCORE_SUMMARY_REPORT_TITLE, hasSchoolLink: true, hasAggregateLink: true },
  'Summary by Student Demographics': {reportPath: SUMMARY_BY_STUDENT_DEMOGRAPHIC_REPORT_TITLE, hasSchoolLink: true, hasAggregateLink: true},
  'District Summary By School': { reportPath: DISTRICT_SUMMARY_BY_SCHOOL_REPORT_TITLE, hasSchoolLink: false, hasAggregateLink: true },
  [HONOR_ROLL_REPORT_DISPLAY_TITLE]: { reportPath: HONOR_ROLL_REPORT_TITLE, hasSchoolLink: true, hasAggregateLink: true },
  'Scholar Summary': { reportPath: SCHOLAR_SUMMARY_REPORT_TITLE, hasSchoolLink: false, hasAggregateLink: true }
};

function SummaryReports({
  reportColumnClass,
  summaryReportsStyle,
  sumReportInfoTrigger,
  isSchoolAdmin,
  selectedYear,
  isDistrictAdmin,
  summaryReports,
  isTeacher,
  genesisSttVal
}) {
  return (<div className={`${reportColumnClass} ${summaryReportsStyle}`}>
    <div className="cb-arch-view">
      <h2 className="infosign">Summary Reports</h2>
      <div className="">
        <input type="image" className="info-icon" style={{marginBottom: '4px'}} src={infoImage} aria-label="Summary reports description" tabIndex="0" id={sumReportInfoTrigger} aria-haspopup="dialog" />
        <SumReportInfoModal trigger={sumReportInfoTrigger} />
      </div>
      <p>Track progress, report on your institution, and plan for the future.</p>
    </div>
    <ul>
      {!isTeacher ? summaryReports.map(report => {
        return <li key={report.reportName}>
          {/* School Admin Reports */}
          {isSchoolAdmin
            ? <ReportLink {...reportMap[report.reportName]} genesisSttVal={genesisSttVal} isSchoolAdmin={isSchoolAdmin} selectedYear={selectedYear} isDistrictAdmin={isDistrictAdmin} report={report} isAggregate={false}></ReportLink>
            : null}
          {/* District Admin Reports */}
          {isDistrictAdmin
            ? <div>
              <div>
                <div>{report.reportName} {!report.enableReport ? <span>(Not Available)</span> : null}</div>
                {/* React District Report */}
                <div>
                  <div>
                    <ReportLink {...reportMap[report.reportName]} genesisSttVal={genesisSttVal} selectedYear={selectedYear} isDistrictAdmin={isDistrictAdmin} report={report} ></ReportLink>
                  </div>
                </div>
              </div>
            </div>
            : null}
        </li>;
      }) : null}
    </ul>
  </div>);
}

SummaryReports.propTypes = {
  isDistrictAdmin: PropTypes.bool,
  isSchoolAdmin: PropTypes.bool,
  isTeacher: PropTypes.bool,
  reportColumnClass: PropTypes.string,
  roleCode: PropTypes.number,
  selectedYear: PropTypes.number,
  sumReportInfoTrigger: PropTypes.string,
  summaryReportsStyle: PropTypes.string,
  summaryReports: PropTypes.array,
  genesisSttVal: PropTypes.string
};

export default SummaryReports;
