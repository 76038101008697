import PropTypes from 'prop-types';
import React from 'react';
import {getReportSubjectSelect} from '../../../../api';
import GenericDropdown from './genericDropdown';

const InterstitialSubjectDropdown = ({
  hasSchoolDropdown,
  reportName,
  sttVal,
  selectedYear,
  setSubjectDropdownSelectedId,
  setSubjectDropdownSelectedName,
  setSelectedAICaption,
  preSelectedSubject,
  showInterstitialPage,
  isVisible,
  setIsReportError,
  schoolDropdownSelectedId,
  optionsOverride,
  label,
  isDistrictAdmin,
  isAggregate,
  setNoDataError
}) => {
  return (
    <>
      <GenericDropdown
        id='selectSubject'
        ariaLabel='Subject Selection'
        isDisabled={() => hasSchoolDropdown && schoolDropdownSelectedId === ''}
        label={label}
        showInterstitialPage={showInterstitialPage}
        isVisible={isVisible}
        setIsReportError={setIsReportError}
        placeholderText={'Select Subject'}
        defaultSelectCaption={preSelectedSubject}
        shouldMakeApiCall={() =>
          !hasSchoolDropdown ||
        // if school value has been selected or there is no school dropdown
        (hasSchoolDropdown && schoolDropdownSelectedId !== '')
        }
        getOptionsApiCall={async () => {
        // @TODO special case - teachers select subject and we only want that shown here. Need to determine best way to get subject id from name
        // if (Array.isArray(optionsOverride)) {
        //   return optionsOverride;
        // }

          const res = await getReportSubjectSelect({
            sttVal,
            adminYear: selectedYear,
            reportName,
            navSchool: schoolDropdownSelectedId, // this may be undefined and it will pull from sttVal
            isDistrictAdmin,
            isAggregate
          });
          !isDistrictAdmin && setSelectedAICaption(res.data.aiCode);
          return { data: res.data.subjectData };
        }}
        handleOptionChange={(value, _label) => {
          setSubjectDropdownSelectedId(value);
          setSubjectDropdownSelectedName(_label);
          setNoDataError('');
        }}
        queryDataUseEffectConditions={[schoolDropdownSelectedId]}
        setNoDataError={setNoDataError}
      />
    </>);
};

InterstitialSubjectDropdown.propTypes = {
  reportName: PropTypes.string,
  selectedYear: PropTypes.number,
  setSubjectDropdownSelectedId: PropTypes.func,
  setSubjectDropdownSelectedName: PropTypes.func,
  setSelectedAICaption: PropTypes.func,
  preSelectedSubject: PropTypes.string,
  showInterstitialPage: PropTypes.bool,
  sttVal: PropTypes.string,
  isVisible: PropTypes.bool,
  setIsReportError: PropTypes.func,
  schoolDropdownSelectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  optionsOverride: PropTypes.array,
  label: PropTypes.string,
  hasSchoolDropdown: PropTypes.bool,
  isDistrictAdmin: PropTypes.bool,
  isAggregate: PropTypes.bool,
  setNoDataError: PropTypes.func
};

export default InterstitialSubjectDropdown;
