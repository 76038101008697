import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { CircularButton, Modal } from '@cb/apricot-react';
import DownloadRow from '../../common/downloads/downloadRow.js';
import docximage from '../../common/downloads/docx-icon.png';
import { getDisplayTier, isTier, getPercentageLabel, getPercentageDisplay, auditWrapper, RECOGNITION_STATUS_TITLE, ACCESS_AWARD_TITLE,
  SCHOOL_ENROLLMENT_TOOLTIP_TITLE, ACCESS_AWARD_TOOLTIP_DESC, ADDITIONAL_INFO, CREDIT_TOOLTIP_DESC, CULTURE_TOOLTIP_DESC,
  OPTIMIZATION_TOOLTIP_DESC, SCHOOL_ENROLLMENT_TOOLTIP_DESC, PRESS_RELEASE_URL, HONOR_ROLL_PRESS_RELEASE_FILENAME
} from '../utils.js';
import { APCentralLink } from '../summaryTab/tooltips.js';
import LinkToHonorRollSchoolReport from '../../common/linkToHonorRollSchool.js';
import { CULTURE, CREDIT, OPTIMIZATION, BRONZE, SILVER, GOLD, PLATINUM } from '../constants.js';

const getAggTableHeaderComponent = (title, modalTriggerId, toolTipLabel) => {
  return (<div style={{display: 'flex'}}>
    {title}
    <div>
      <CircularButton onClick={(e) => {
        e.stopPropagation();
      }} icon="question" light small className="cb-margin-left-4" title={toolTipLabel} label={toolTipLabel} aria-haspopup="dialog" tabIndex="0" id={modalTriggerId}/>
    </div>
  </div>);
};

const modalTriggerIds = {
  recognitionStatusModal: 'recognitionStatusModalTriggerDA',
  accessAwardModal: 'accessAwardModalTriggerDA',
  cultureModal: 'cultureModalTriggerDA',
  creditModal: 'creditModalTriggerDA',
  optimizationModal: 'optimizationModalTriggerDA'
};
const modalContents = [{
  title: ACCESS_AWARD_TITLE,
  trigger: modalTriggerIds.accessAwardModal,
  modalText: ACCESS_AWARD_TOOLTIP_DESC,
  additionalInfo: true
}, {
  title: CULTURE,
  trigger: modalTriggerIds.cultureModal,
  modalText: CULTURE_TOOLTIP_DESC,
  additionalInfo: true
}, {
  title: CREDIT,
  trigger: modalTriggerIds.creditModal,
  modalText: CREDIT_TOOLTIP_DESC,
  additionalInfo: true
}, {
  title: OPTIMIZATION,
  trigger: modalTriggerIds.optimizationModal,
  modalText: OPTIMIZATION_TOOLTIP_DESC,
  additionalInfo: true
}, {
  title: SCHOOL_ENROLLMENT_TOOLTIP_TITLE,
  trigger: modalTriggerIds.recognitionStatusModal,
  modalText: SCHOOL_ENROLLMENT_TOOLTIP_DESC
}];

export const TableHeaderModals = () => {
  return modalContents.map(({title, trigger, modalText, additionalInfo}, id) =>
    <Modal key={trigger + id} title={title} trigger={trigger} withFooter={false}>
      <div><p className='cb-font-weight-regular'>
        {modalText}
        {additionalInfo && <> {ADDITIONAL_INFO}
          <APCentralLink />.
        </>
        }
      </p></div>
    </Modal>);
};

export const getTableData = ({data, year, sttVal, isDistrictAdmin, setHasRun}) => data.map((school) => {
  const schoolNameAICode = school.org_short_name + ' (' + school.ai_cd + ')';
  return {
    schoolNameAICode,
    schoolInfo: {schoolName: schoolNameAICode, orgId: school.org_id, year, sttVal, isDistrictAdmin, setHasRun},
    overall_tier: isTier(getDisplayTier(school.overall_tier)) ? school.overall_tier : 'n/a',
    access_award: school.dei_award,
    college_culture_pct: getPercentageDisplay(getPercentageLabel(school.college_culture_pct)),
    college_culture_pct_num: school.college_culture_pct === null ? -1 : parseInt(school.college_culture_pct),
    college_credit_pct: getPercentageDisplay(getPercentageLabel(school.college_credit_pct)),
    college_credit_pct_num: school.college_credit_pct === null ? -1 : parseInt(school.college_credit_pct),
    college_optimization_pct: getPercentageDisplay(getPercentageLabel(school.college_optimization_pct)),
    college_optimization_pct_num: school.college_optimization_pct === null ? -1 : parseInt(school.college_optimization_pct)
  };
}).sort((a, b) => a.schoolNameAICode.localeCompare(b.schoolNameAICode));

export const getTableColumns = (year) => {
  return [
    {
      title: 'School',
      field: 'schoolInfo',
      rowHeader: true,
      sortable: true,
      component: ({value}) => <LinkToHonorRollSchoolReport
        year={value.year}
        isDistrictAdmin={value.isDistrictAdmin}
        schoolName={value.schoolName}
        orgId={value.orgId}
        sttVal={value.sttVal}
        setHasRun={value.setHasRun}
      />
    },
    { title: `${year} ${RECOGNITION_STATUS_TITLE}`,
      field: 'overall_tier',
      sortable: true,
      classNameFunc: (value) => {
        if (value === BRONZE) {
          return 'bronzeTableCell';
        }
        if (value === SILVER) {
          return 'silverTableCell';
        }
        if (value === GOLD) {
          return 'goldTableCell';
        }
        if (value === PLATINUM) {
          return 'platinumTableCell';
        }
        return '';
      },
      headerComponent: ({title}) => getAggTableHeaderComponent(title, modalTriggerIds.recognitionStatusModal, SCHOOL_ENROLLMENT_TOOLTIP_TITLE + ' Tooltip')},
    { title: `${year} ${ACCESS_AWARD_TITLE} Status`, field: 'access_award', sortable: true, headerComponent: ({title}) => getAggTableHeaderComponent(title, modalTriggerIds.accessAwardModal, ACCESS_AWARD_TITLE + ' Tooltip')},
    { title: CULTURE, field: 'college_culture_pct', sortable: true, className: 'rightAlignTableCells', headerComponent: ({title}) => getAggTableHeaderComponent(title, modalTriggerIds.cultureModal, CULTURE + ' Tooltip')},
    { title: CREDIT, field: 'college_credit_pct', sortable: true, className: 'rightAlignTableCells', headerComponent: ({title}) => getAggTableHeaderComponent(title, modalTriggerIds.creditModal, CREDIT + ' Tooltip')},
    { title: OPTIMIZATION, field: 'college_optimization_pct', sortable: true, className: 'rightAlignTableCells', headerComponent: ({title}) => getAggTableHeaderComponent(title, modalTriggerIds.optimizationModal, OPTIMIZATION + ' Tooltip')}
  ];
};

export const getCSVHeaders = (year) => ['School', 'AI Code', `${year} ${RECOGNITION_STATUS_TITLE}`, `${year} ${ACCESS_AWARD_TITLE} Status`, CULTURE, CREDIT, OPTIMIZATION];

export const getCSVData = ({data, year}) => data.map((el) => ({
  School: el.org_short_name,
  'AI Code': el.ai_cd,
  [`${year} ${RECOGNITION_STATUS_TITLE}`]: isTier(getDisplayTier(el.overall_tier)) ? el.overall_tier : 'n/a',
  [`${year} ${ACCESS_AWARD_TITLE} Status`]: el.dei_award,
  [CULTURE]: getPercentageDisplay(getPercentageLabel(el.college_culture_pct)),
  [CREDIT]: getPercentageDisplay(getPercentageLabel(el.college_credit_pct)),
  [OPTIMIZATION]: getPercentageDisplay(getPercentageLabel(el.college_optimization_pct))
})).sort((a, b) => a.School.localeCompare(b.School));

export const hasTierSchools = data => data.some(school => isTier(getDisplayTier(school.overall_tier)));

export const PressReleaseModal = ({year, trigger, sttVal, org, isAggregate, isDistrictAdmin}) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal title={`AP School Honor Roll, District Press Release ${year}`} trigger={trigger} withFooter={false} modalId='pressReleaseModal'>
      <div className="container">
        <div className="row">
          <h3 className="cb-font-weight-bold cb-sans-serif cb-font-size-regular">
    Available for download:
          </h3>
        </div>
        <div className="row">
          <br />
        </div>

        <DownloadRow
          filename={HONOR_ROLL_PRESS_RELEASE_FILENAME}
          description={'Share with your community!'}
          buttonId={'dlHonorRollTemplateDA'}
          buttonTitle={'Download AP School Honor Roll - District Press Release DOCX'}
          imageSrc={docximage}
          text={'AP School Honor Roll - District Press Release DOCX'}
          downloadFile={async () => {
            setIsLoading(true);
            await auditWrapper(() => {
              const a = document.createElement('a');
              a.href = PRESS_RELEASE_URL;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            }, 'DOCX', 'HonorRollDATemplate', sttVal, year, org, isAggregate, isDistrictAdmin);
            setIsLoading(false);
          }}
          isLoading={isLoading}
          noStyling={true}
        />
      </div>
    </Modal>
  );
};

PressReleaseModal.propTypes = {
  year: PropTypes.number,
  trigger: PropTypes.string,
  sttVal: PropTypes.string,
  org: PropTypes.number,
  isAggregate: PropTypes.bool,
  isDistrictAdmin: PropTypes.bool
};
