import PropTypes from 'prop-types';
import React from 'react';
import SubHeader from '../../subHeader.js';
import HonorRollFooter from '../footer.js';
import { getTableColumns, getTableData, getCSVHeaders, getCSVData, hasTierSchools, TableHeaderModals, PressReleaseModal } from './utils.js';
import HonorRollDATable from './tableSection.js';
import DownloadModal from '../../common/downloads/downloadModal.js';
import { HONOR_ROLL_REPORT_DISPLAY_TITLE_DA } from '../../../../constants/reports.js';
import { formatCountValue } from '../../../../utils/stringUtils.js';
import { CULTURE, CREDIT, OPTIMIZATION } from '../constants.js';
import { getMetaData, getCriteriaTableDataForDA } from '../dataParser.js';

const HonorRollDA = ({ apiData, year, selectedOrgId, sttVal, isDistrictAdmin, isAggregate, setHasRun }) => {
  const downloadModalTrigger = 'openDADownloadModal';
  const pressReleaseModalTrigger = 'openPressReleaseModal';

  const cultureMetadata = getMetaData(CULTURE, apiData.metaDataValues);
  const creditMetadata = getMetaData(CREDIT, apiData.metaDataValues);
  const optimizationMetadata = getMetaData(OPTIMIZATION, apiData.metaDataValues);

  const criteriaTableData = getCriteriaTableDataForDA({cultureMetadata, creditMetadata, optimizationMetadata });

  const columns = getTableColumns(year);
  const tableData = getTableData({data: apiData.honorRollData, year, sttVal, isDistrictAdmin, setHasRun});

  const showPressReleaseButton = hasTierSchools(apiData.honorRollData);
  const pressReleaseButton = showPressReleaseButton ? [{ title: 'Download Press Release', id: pressReleaseModalTrigger, onPress: () => {}, icon: 'cb-download', hasPopup: true}] : undefined;

  return (
    <>
      <SubHeader showDownload={true} downloadModalTrigger={downloadModalTrigger} additionalButtons={pressReleaseButton} />
      <PressReleaseModal trigger={pressReleaseModalTrigger} year={year} sttVal={sttVal} org={selectedOrgId} isAggregate={isAggregate} isDistrictAdmin={isDistrictAdmin} />
      <DownloadModal
        containerId='hr_da_download_modal'
        trigger={downloadModalTrigger}
        title={<div style={{fontSize: 26}}>
          {`${HONOR_ROLL_REPORT_DISPLAY_TITLE_DA} ${year}`}
        </div>}
        filename={`AP District School Honor Roll Progress Report ${year}`}
        csvFilterText={''}
        auditTitle={'HonorRollDAExport'}
        sttVal={sttVal}
        year={year}
        isAggregate={isAggregate}
        isDistrictAdmin={isDistrictAdmin}
        csvData={getCSVData({year, data: apiData.honorRollData})}
        csvHeader={getCSVHeaders(year)}
      />
      <div className="container content-gutter">
        <div className='cb-margin-top-8' id='main_tab_container' >
          <div className={'cb-padding-top-24 cb-padding-bottom-16 cb-font-weight-medium'}>
            {`Total Schools: ${Array.isArray(apiData.honorRollData) ? formatCountValue(apiData.honorRollData.length) : ''}`}
          </div>
          <HonorRollDATable tableData={tableData} columnData={columns} />
          <TableHeaderModals />
        </div>
        <HonorRollFooter year={year} showNaMessage={true} isAggregate={isAggregate} criteriaTableData={criteriaTableData}/>
      </div>
    </>

  );
};

HonorRollDA.propTypes = {
  apiData: PropTypes.object,
  year: PropTypes.number,
  selectedOrgId: PropTypes.number,
  sttVal: PropTypes.string,
  selectedAICaption: PropTypes.string,
  isDistrictAdmin: PropTypes.bool,
  isAggregate: PropTypes.bool,
  setHasRun: PropTypes.func
};

export default HonorRollDA;
