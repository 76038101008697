import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { AppContext } from '../../../../AppContext';
import csvImage from '../../csvImage.png';
import { Modal, YellowButton, Icon } from '@cb/apricot-react';
import openDownloadInNewTab from '../../../ap-osre-native-reports/common/downloads/openDownloadTab';
import gql from 'graphql-tag';
import { subscribeToStudentData } from '../../../../graphql/subscriptions';
import { getAuthStudentData } from '../../../../graphql/queries';
import { getAppSyncResponseHandler } from '../../../ap-osre-native-reports/common/pdf/common';

const getSDD = ({
  setFileData,
  orgId,
  roleCd,
  selectedYear,
  sttVal,
  reportName
}) => {
  const cb = window.cb;
  openDownloadInNewTab({
    subscribeParams: {
      query: gql(subscribeToStudentData),
      variables: {
        catapultId: cb.core.iam.getTempAWSCredsSubject(),
        orgId: parseInt(orgId),
        adminYear: parseInt(selectedYear),
        roleCd,
        reportName
      }
    },
    queryParams: {
      query: gql(getAuthStudentData),
      variables: {
        adminYear: parseInt(selectedYear),
        sttVal,
        reportName
      }
    },
    downloadFile: getAppSyncResponseHandler('subscribeToStudentData', setFileData)
  });
};
const SddModal = ({ roleCd, selectedYear, studentDataDownloadTrigger, genesisSttVal, selectedOrgId}) => {
  const appContext = useContext(AppContext);
  const linkRef = useRef(undefined);
  const [fileData, setFileData] = useState();
  useEffect(() => {
    if (fileData) {
      linkRef?.current?.click();
    }
  }, [fileData]);
  return (
    <Modal
      modalId='studentDatafileModalContainer'
      title={<b id={'studentDatafileModalHeader'}>Export Student Datafile CSV</b>}
      trigger={studentDataDownloadTrigger}
      footer={
        <YellowButton
          onClick={async () => {
            await getSDD({
              setFileData,
              orgId: selectedOrgId,
              roleCd,
              selectedYear,
              sttVal: genesisSttVal,
              reportName: 'StudentDataDownload'
            }
            );
          }}
          id={'studentDatafileDownloadButton'}
          data-cbtrack-download={'student-data-file-' + selectedYear + '.csv'}
        >
          Export Report
        </YellowButton>
      }
    >
      {/* This is a hidden link to facilitate csv download */}
      <a
        ref={linkRef}
        href={fileData}
        download={`AP Student Datafile ${selectedYear}.csv`}
        className="display-none">
        Download
      </a>
      <div
        id="studentDataFileModal"
        className="modal fade cb-modal-animation"
        role="dialog"
        aria-label="Export student data file"
        tabIndex="-1"
      >
        <div className="modal-dialog" role="document">
          <div>
            <span className="padding-right">
              <img src={csvImage} alt="" />
            </span>
            <span className="cvs-modal-text bold-text">
              This report is available in CSV format
            </span>
          </div>
          <div className="cvs-modal-text padding-bottom-text">
            You can download & open this file in Excel, or
            import into your SIS.{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                appContext?.content?.ap_central_links
                  ?.studentDataFileFormatLink
              }
            >
              View the layout specification
            </a>
          </div>
          <div>
            <Icon name={'window'} className='padding-right' />
            <span className="cvs-modal-text">
              Your download will open in a new tab and may take
              a few moments to load. Do not close the tab during
              this time.
            </span>
          </div>
        </div>
      </div>
    </Modal>
  );
};

SddModal.propTypes = {
  roleCd: PropTypes.number,
  selectedYear: PropTypes.number,
  studentDataDownloadTrigger: PropTypes.string,
  genesisSttVal: PropTypes.string,
  selectedOrgId: PropTypes.number
};

export default SddModal;
