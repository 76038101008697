module.exports = {
  // Messages
  REPORT_ERROR_MESSAGE: 'We are unable to provide you with your report at this time. Please try again at a later time.',
  NO_DATA_ERROR_MESSAGE: 'Data for this organization are not available for the selected year.',
  NO_DATA_SUBJECT_ERROR_MESSAGE: 'There are no data available for the selected school.',
  NO_DATA_SECTION_ERROR_MESSAGE: 'There are no data available for the selected subject.',
  NO_DATA_FILTERED_ERROR_MESSAGE: 'There are no data available for the applied filters.',
  // Five year score summary values
  FIVE_YEAR_SCORE_SUMMARY_REPORT_TITLE: 'FiveYearSchoolScoreSummary',
  FIVE_YEAR_SCORE_SUMMARY_URI: 'fiveYearScoreSummaryData',
  // Equity And Excellence values
  EQUITY_AND_EXCELLENCE_REPORT_TITLE: 'EquityAndExcellence',
  EQUITY_AND_EXCELLENCE_REPORT_URI: 'equityExcellenceData',
  // Current year score summary values
  CURRENT_YEAR_SCORE_SUMMARY_REPORT_TITLE: 'CurrentYearScoreSummary',
  CURRENT_YEAR_SCORE_SUMMARY_URI: 'currentYearScoreSummaryData',
  // Summary By Student Demographic values
  SUMMARY_BY_STUDENT_DEMOGRAPHIC_REPORT_TITLE: 'SummaryByStudentDemographics',
  SUMMARY_BY_STUDENT_DEMOGRAPHIC_URI: 'studentDemographicsData',

  // District Summary by School
  DISTRICT_SUMMARY_BY_SCHOOL_REPORT_TITLE: 'DistrictSummaryBySchool',
  DISTRICT_SUMMARY_BY_SCHOOL_URI: 'districtSummaryBySchoolData',

  // Scholar Summary
  SCHOLAR_SUMMARY_REPORT_TITLE: 'ScholarSummary',
  SCHOLAR_SUMMARY_URI: 'scholarSummaryData',

  // School honor roll values
  HONOR_ROLL_REPORT_DISPLAY_TITLE: 'AP School Honor Roll Progress Report',
  HONOR_ROLL_REPORT_DISPLAY_TITLE_DA: 'AP School Honor Roll Progress Report, Aggregated for Districts',
  HONOR_ROLL_REPORT_TITLE: 'HonorRoll',
  HONOR_ROLL_URI: 'honorRollData',
  HONOR_ROLL_LETTER_PDF_URI: 'honorRollLetter',
  HONOR_ROLL_CERTIFICATE_PDF_URI: 'honorRollCertificate',

  // Instructional Planning roll values
  INSTRUCTIONAL_PLANNING_REPORT_DISPLAY_TITLE: 'Instructional Planning Report',
  INSTRUCTIONAL_PLANNING_REPORT_DISPLAY_TITLE_DA: 'Instructional Planning Report, Aggregated for Districts',
  INSTRUCTIONAL_PLANNING_REPORT_TITLE: 'InstructionalPlanningReport',
  INSTRUCTIONAL_PLANNING_REPORT_TITLE_DA: 'InstructionalPlanningReportDA',
  INSTRUCTIONAL_PLANNING_URI: 'iprData',
  INSTRUCTIONAL_PLANNING_SINGLE_PDF_ID_SCHOOL: 'IPRSinglePdfExport',
  INSTRUCTIONAL_PLANNING_SINGLE_PDF_ID_DISTRICT: 'IPRSinglePdfDAExport',

  // Student Score Report values
  STUDENT_SCORE_REPORT_TITLE: 'StudentScoreReport',
  STUDENT_SCORE_URI: 'studentScoreData',

  // Org Score Roster Report values
  ORG_SCORE_ROSTER_REPORT_TITLE: 'OrgScoreRoster',
  ORG_SCORE_ROSTER_REPORT_URI: 'orgScoreRosterData',

  // Subject Score Roster Report values
  SUBJECT_SCORE_ROSTER_REPORT_DISPLAY_TITLE: 'Subject Score Roster',
  SUBJECT_SCORE_ROSTER_REPORT_DISPLAY_TITLE_DA: 'Subject Score Roster, Aggregated for Districts',
  SUBJECT_SCORE_ROSTER_REPORT_TITLE: 'SubjectScoreRoster',
  SUBJECT_SCORE_ROSTER_REPORT_URI: 'subjectScoreRosterData',
  SUBJECT_SCORE_ROSTER_REPORT_SINGLE_PDF_URI: 'ssrSinglePDF',
  SUBJECT_SCORE_ROSTER_SINGLE_PDF_ID_SCHOOL: 'SSRSinglePdfExport',
  SUBJECT_SCORE_ROSTER_SINGLE_PDF_ID_DISTRICT: 'SSRSinglePdfDAExport',

  // Scholar Roster Report values
  SCHOLAR_ROSTER_REPORT_TITLE: 'ScholarRoster',
  SCHOLAR_ROSTER_REPORT_URI: 'scholarRosterData',

  // Interstitial values
  ORGS_URI: 'orgs',
  SUBJECTS_URI: 'subjects',
  SECTIONS_URI: 'sections',
  STUDENTS_URI: 'students',
  HRORGS_URI: 'hrorgs',

  DISABLED_REPORT_LINK_CSS_CLASS: 'disableReport cb-disabled',
  ACTIVE_REPORT_LINK_CSS_CLASS: 'linkPointer cb-palette-blue2'
};
