import PropTypes from 'prop-types';
import React from 'react';
import HonorRollSchool from './school';
import HonorRollDA from './districtAgg';
import HonorRollNotEligibleDisplay from './notEligibleDisplay';
import ReportContainer from '../common/reportContainer';

const HonorRoll = (props) => {

  const { isAggregate, apiData } = props;

  // if school/district is not eligible, show message
  if (!apiData.eligible) {
    return (<ReportContainer><HonorRollNotEligibleDisplay /></ReportContainer>);
  }

  return (
    <>
      {isAggregate ? <HonorRollDA {...props} />
        : <HonorRollSchool {...props} />}
    </>
  );
};

HonorRoll.propTypes = {
  isAggregate: PropTypes.bool,
  apiData: PropTypes.object,
  year: PropTypes.number,
  selectedOrgId: PropTypes.number,
  sttVal: PropTypes.string,
  selectedAICaption: PropTypes.string,
  isDistrictAdmin: PropTypes.bool,
  dataUpdatedDate: PropTypes.string
};

export default HonorRoll;
