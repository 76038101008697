/* eslint-disable react-hooks/rules-of-hooks */
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { AppContext } from './../../AppContext';
import {
  NO_DATA_ERROR_MESSAGE, REPORT_ERROR_MESSAGE
} from './../../constants/reports';
import ReportError from './../ap-osre-error-handling/ReportError';
import Spinner from './../common/loader/spinner';
import InterstitialPage from './common/interstitial/interstitialPage';
import ReportHeader from './header';
import { reportMap } from './reportMap';
import './shared.css';
import './table.css';

const NativeReports = (props) => {
  const { id } = useParams();
  // get report specific information from the reportMap object, using the currently opened report id
  if (typeof reportMap[id] === 'undefined') { // if user has manually entered an invalid reportName, redirect home
    return <Redirect to={{
      pathname: '/'
    }} />;
  }

  const {
    reportName,
    Report,
    callApi,
    getApiFunction,
    getReportTitle,
    shouldShowInterstitialPage = () => false,
    shouldShowInterstitialSchoolDropdown = () => false,
    shouldShowInterstitialSubjectDropdown = () => false,
    shouldShowInterstitialSectionDropdown = () => false,
    showInterstitialStudentComponent = false
  } =
      reportMap[id];

  const ref = useRef(null);
  const appContext = useContext(AppContext);
  let location = useLocation();
  const isDistrictAdmin = Boolean(location.params?.isDistrictAdmin);
  const isTeacher = Boolean(location.params?.isTeacher);
  const isAggregate = Boolean(location.params?.isAggregate);
  const year = Number(location.params?.year);
  const { subjectName: defaultSubjectName, candId, orgId: navLinkOrgId, schoolName } = location.params || {};
  // it's a bit odd to get roleCd from here, but only place I'm seeing it readily available
  const roleCd = Array.isArray(props.reportsData) ? props.reportsData[0].role_cd : '';
  const [isLoading, setIsLoading] = useState(false);
  const [isNoData, setIsNoData] = useState(false);
  const [reportHandledNoData, setReportHandledNoData] = useState(false);
  const [isReportError, setIsReportError] = useState(false);
  const [showInterstitialPage, setShowInterstitialPage] = useState(
    shouldShowInterstitialPage({ isDistrictAdmin, isAggregate })
  );

  const showInterstitialSchoolDropdown = shouldShowInterstitialSchoolDropdown({ isTeacher, isDistrictAdmin, isAggregate });
  const showInterstitialSubjectDropdown = shouldShowInterstitialSubjectDropdown({ isTeacher, isDistrictAdmin, isAggregate });
  const showInterstitialSectionDropdown = shouldShowInterstitialSectionDropdown({ isTeacher, isDistrictAdmin, isAggregate });

  const [additionalReportProps, setAdditionalReportProps] = useState({});

  // boolean used to track whether the report has been generated at least once
  const [hasRun, setHasRun] = useState(false);

  const [apiData, setApiData] = useState({});

  const [selectedAICaption, setSelectedAICaption] = useState('');
  const [selectedSubjectId, setSelectedSubjectId] = useState('');
  const [selectedSectionIds, setSelectedSectionIds] = useState('');
  const [selectedSubjectName, setSelectedSubjectName] = useState('');
  const [selectedSectionNames, setSelectedSectionNames] = useState('');
  const [selectedStudentId, setSelectedStudentId] = useState('');
  const [selectedStudentName, setSelectedStudentName] = useState('');
  const [selectedOrgId, setSelectedOrgId] = useState(
    props.searchedOrg?.orgId || props.selectedOrg.orgId
  );
  const [selectedOrgName] = useState(
    props.searchedOrg?.orgName || props.selectedOrg.orgName
  );
  const [dataUpdatedDate, setDataUpdatedDate] = useState('');

  // Generic function to query report data, this is called...
  //  1. If no interstitial page needed - on report page load
  //  2. If there is an interstitial page - when run report button is clicked
  const queryData = async ({ sttVal, navSchool, navYear, isDistrictAdmin, isAggregate, navSubject, navSection, navStudent, reportHandlesNoData }, additionalProps = {}) => {
    setReportHandledNoData(false);
    setIsNoData(false);

    // set isLoading boolean to true, so we show loading indicator to user while this data is queried
    setIsLoading(true);

    // set report error boolean to false
    setIsReportError(false);

    // clear data updated date
    setDataUpdatedDate('');

    try {
      // if there is a getApiFunction prop for this report, call that to determine which func to invoke to get data
      const getData = getApiFunction ? getApiFunction({isAggregate}) : callApi;

      // make the API call to retrieve data
      let { data = {} } = await getData({
        selectedOrgId,
        year,
        roleCd,
        sttVal,
        navSchool,
        navYear,
        isDistrictAdmin,
        isAggregate,
        navSubject,
        navSection,
        navStudent,
        ...additionalProps
      });

      const { noData, aiCode, lastUpdatedDate } = data;

      // if report handles no data, set to true and return
      if (noData && reportHandlesNoData) {
        setReportHandledNoData(true);
        setIsLoading(false);
        return;
      }

      if (noData) {
        // set no data boolean to true so that no data message is displayed
        setIsNoData(true);
        setHasRun(true);
        setIsLoading(false);
        return;
      }

      // set api data and let specific report component to deal with the dataUpdatedDate
      setApiData(data);

      // Update report header if we got AI code back from call
      if (aiCode && !isDistrictAdmin && !showInterstitialPage) {
        setSelectedAICaption(aiCode);
      }

      // Update dataUpdatedDate value, which is shown in report header
      if (lastUpdatedDate) {
        const lastUpdated = new Date(lastUpdatedDate);
        setDataUpdatedDate(
          lastUpdated.toLocaleString('default', {
            timeZone: 'UTC', // sql query return UTC datetime string despite the fact that the value is EST
            year: 'numeric',
            month: 'long',
            day: '2-digit'
          }) ?? ''
        );
      }

      // set hasRun boolean to true, so that report component is shown
      setHasRun(true);
      setIsLoading(false);
    } catch (err) {
      // This means an API error (or an unexpected js error above) occurred. Show the error screen.
      setIsReportError(true);
      setHasRun(true);
      setIsLoading(false);
      // eslint-disable-next-line no-console
      console.log('error: ', err);
    }
  };

  // Allows querying of report data from report UI changes (Ex. student demographic changing demo on report)
  const queryDataFromReport = async (additionalProps = {}, reportHandlesNoData) => {
    setAdditionalReportProps(additionalProps);
    return queryData({
      sttVal: location.params?.genesisSttVal,
      navSchool: selectedOrgId,
      navYear: year,
      isDistrictAdmin,
      isAggregate,
      navSubject: selectedSubjectId,
      navSection: selectedSectionIds,
      navStudent: selectedStudentId,
      reportHandlesNoData
    },
    additionalProps
    );
  };

  // query report data from genesis api.
  // called on load and when run report button on interstitial page is selected
  const runReport = (orgId = selectedOrgId, subjectId = selectedSubjectId, sectionIds = selectedSectionIds, studentId = (selectedStudentId || candId),
    shouldRun = !showInterstitialPage
  ) => {
    window.scrollTo(0, 0);
    setAdditionalReportProps({});
    if (isDistrictAdmin && isAggregate) {
      setSelectedAICaption(`${selectedOrgName} (D${selectedOrgId})`);
    } else if (!isDistrictAdmin && !selectedAICaption) {
      setSelectedAICaption(selectedOrgName);
    }

    if (shouldRun) {
      queryData({
        sttVal: location.params?.genesisSttVal,
        navSchool: orgId,
        navYear: year,
        isDistrictAdmin,
        isAggregate,
        navSubject: subjectId,
        navSection: sectionIds,
        navStudent: studentId
      });
    }
  };

  useEffect(() => {
    if (candId) {
      setSelectedStudentName(location.params?.fullName || '');
      setSelectedStudentId(candId || '');
    }
    if (navLinkOrgId) {
      setSelectedAICaption(schoolName || '');
      return runReport(navLinkOrgId);
    }
    runReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candId, navLinkOrgId]);

  return (
    <>
      {!location.params || !ref ? (
        <Redirect to="/" />
      ) : (
        <>
          <div className="full-container">
            <div className="row">
              <div className="col-xs-12 cb-no-padding-xs">
                <ReportHeader
                  title={getReportTitle(isAggregate)}
                  year={year}
                  school={selectedAICaption}
                  subject={selectedSubjectName || defaultSubjectName }
                  section={selectedSectionNames}
                  student={selectedStudentName}
                  showInterstitialSubjectDropdown={showInterstitialSubjectDropdown}
                  showInterstitialSectionDropdown={showInterstitialSectionDropdown}
                  showInterstitialStudentComponent={showInterstitialStudentComponent}
                  updateDate={dataUpdatedDate}
                  // only show the customize option in this header if user is DA and they aren't already being shown the school selection dropdown
                  showCustomize={
                    shouldShowInterstitialPage({ isDistrictAdmin, isAggregate }) &&
                    !showInterstitialPage &&
                    !isReportError &&
                    hasRun &&
                    !isLoading
                  }
                  customizeHandler={() => {
                    setShowInterstitialPage(true);
                  }}
                />
                <InterstitialPage
                  reportName={reportName}
                  title={getReportTitle(isAggregate)}
                  selectedYear={year}
                  sttVal={location.params?.genesisSttVal}
                  isNoData={isNoData}
                  setIsNoData={setIsNoData}
                  selectedOrgId={selectedOrgId}
                  setSelectedSubjectId={setSelectedSubjectId}
                  setSelectedSubjectName={setSelectedSubjectName}
                  setSelectedOrgId={setSelectedOrgId}
                  selectedAICaption={selectedAICaption}
                  setSelectedAICaption={setSelectedAICaption}
                  dataUpdatedDate={dataUpdatedDate}
                  showInterstitialPage={showInterstitialPage}
                  isDistrictAdmin={isDistrictAdmin}
                  isReportError={isReportError}
                  setIsReportError={setIsReportError}
                  hasRun={hasRun}
                  isLoading={isLoading}
                  setShowInterstitialPage={setShowInterstitialPage}
                  isAggregate={isAggregate}
                  interstitialSchoolDropdown={showInterstitialSchoolDropdown}
                  interstitialSubjectDropdown={showInterstitialSubjectDropdown}
                  interstitialSectionDropdown={showInterstitialSectionDropdown}
                  interstitialStudentComponent={showInterstitialStudentComponent}
                  onRunReport={(orgId, subjectId, sectionIds, studentId) => {
                    runReport(orgId, subjectId, sectionIds, studentId, true);
                  }}
                  preSelectedSubject={defaultSubjectName}
                  setSelectedSectionIds={setSelectedSectionIds}
                  setSelectedSectionNames={setSelectedSectionNames}
                  selectedStudentId={selectedStudentId}
                  setSelectedStudentId={setSelectedStudentId}
                  setSelectedStudentName={setSelectedStudentName}
                  defaultValue={location.params}
                />
                {isLoading ? (
                  <Spinner />
                ) // if there has been a report error (network error / 5xx)
                  : isReportError ? (
                    <ReportError message={REPORT_ERROR_MESSAGE} />
                  ) // if there is no data for this user, show no data message
                    : (isNoData && !showInterstitialPage) ? (
                      <ReportError message={NO_DATA_ERROR_MESSAGE} />
                    ) // there are no errors and data has been returned, show report
                      : showInterstitialPage || !hasRun || isNoData || isReportError ? null : (
                        <Report
                          {...props}
                          {...additionalReportProps}
                          roleCd={roleCd}
                          queryDataFromReport={queryDataFromReport} // for reports that may need to refresh data from report UI (summary by student demographic)
                          setAdditionalReportProps={setAdditionalReportProps} // allows reports to maintain data between load and interstitial page
                          isAggregate={isAggregate}
                          year={year}
                          apiData={apiData}
                          currentYear={appContext.currentYear}
                          sttVal={location.params?.genesisSttVal}
                          isDistrictAdmin={isDistrictAdmin}
                          isTeacher={isTeacher}
                          selectedOrgId={selectedOrgId}
                          selectedSubjectId={selectedSubjectId}
                          selectedSectionIds={selectedSectionIds}
                          selectedSubjectName={selectedSubjectName}
                          selectedSectionNames={selectedSectionNames}
                          selectedAICaption={selectedAICaption}
                          dataUpdatedDate={dataUpdatedDate}
                          reportHandledNoData={reportHandledNoData}
                          reportTitle={getReportTitle(isAggregate)}
                          setHasRun={setHasRun}
                        />
                      )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

NativeReports.propTypes = {
  selectedOrg: PropTypes.object,
  isDistrictAdmin: PropTypes.bool,
  searchedOrg: PropTypes.object,
  reportsData: PropTypes.array
};

export default NativeReports;
