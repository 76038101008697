import React, { useState, useEffect, useContext, Fragment} from 'react';
import PropTypes from 'prop-types';
import analytics from '../../utils/analytics';
import './homeSignedIn.css';
import ShowError from '../ap-osre-error-handling/ErrorBoundary';
import NoCourseAuth from '../ap-osre-error-handling/noCourseAuth';
import Spinner from '../common/loader/spinner';
import { AppContext } from '../../AppContext';
import DashboardContent from './DashboardContent';
import DashboardHeader from './DashboardHeader';
import {postMessages, getReportList} from '../../api';
import axios from 'axios';
import { getRoleCodeFromReportList } from '../../utils/roleCodeMap';

const HomeSignedIn = (props) => {
  const appContext = useContext(AppContext);
  const [reportsData, setReportsData] = useState(props.reportsData);
  const [sdiMessages, setSdiMessages] = useState(props.sdiMessages);
  const [courses, setCourses] = useState(props.courses);
  const [genMessages, setGenMessages] = useState(props.genMessages);
  const [genesisSttVal, setGenesisSttVal] = useState(props.genesisSttVal);
  const [yearChanged, setYearChanged] = useState(false);
  const [selectedYear, setSelectedYear] = useState(appContext.currentYear);
  const [notCourseAuthorized, setNotCourseAuthorized] = useState(false);
  const [yearMessageList, setYearMessageList] = useState([]);
  const [allYearsMessageList, setAllYearsMessageList] = useState([]);
  const [hasErrorOnYearChange, setHasErrorOnYearChange] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!appContext.isCustomerService) {
      let dashboardView = '';
      dashboardView = selectedYear !== appContext.currentYear ? 'dashboard-' + selectedYear : 'dashboard';
      analytics.reportCurrentView(dashboardView, appContext.roleCd);
    }
    let yearMessage = [];
    let allYearsMessage = [];
    if (genMessages) {
      genMessages.forEach((message, i) => {
        message.role_cd.forEach(code => {
          if (code === reportsData[0].role_cd) {
            if (message.all_years) {
              allYearsMessage.push(message);
            } else {
              yearMessage.push(message);
            }
          }
        });
      });
      setYearMessageList(yearMessage);
      setAllYearsMessageList(allYearsMessage);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (yearChanged) {
      let isCurrent = true;
      const fetchData = async () => {
        setIsLoading(true);
        if (hasErrorOnYearChange) {
          setHasErrorOnYearChange(undefined);
        }

        try {
          let [reports, messages] = await axios.all([
            getReportList({
              orgId: props.searchedOrg?.orgId ? Number(props.searchedOrg.orgId) : Number(props.selectedOrg.orgId),
              roleCd: props.searchedOrg ? props.searchedOrg.roleCd : undefined,
              year: selectedYear,
              currentYear: appContext.currentYear
            }),
            postMessages(selectedYear)
          ]);
          appContext.setRoleCd(getRoleCodeFromReportList(reports.data));
          // Both requests are now complete
          if (isCurrent) {
            setReportsState(reports.data);
            setMessagesState(messages.data);
            setIsLoading(false);
          }
        } catch (err) {
          const errorObj = err && err.response && err.response.data ? err.response.data : err;
          if (isCurrent) {
            setHasErrorOnYearChange(errorObj);
            setYearChanged(false);
            setIsLoading(false);
          }
        }
      };
      fetchData();
      return () => {
        isCurrent = false;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearChanged, selectedYear]);

  const setReportsState = reports => {
    if (reports.SDIMessages) {
      const currentSdiMessages = sdiMessages ? Object.keys(sdiMessages) : [];
      if (Object.keys(reports.SDIMessages).length !== currentSdiMessages.length) {
        setSdiMessages(reports.SDIMessages);
      } else {
        if ((reports.SDIMessages.hasOwnProperty('noDataReport') && !currentSdiMessages.hasOwnProperty('noDataReport')) ||
          (!reports.SDIMessages.hasOwnProperty('noDataReport') && currentSdiMessages.hasOwnProperty('noDataReport'))) {
          setSdiMessages(reports.SDIMessages);
        }
        if ((reports.SDIMessages.hasOwnProperty('noIprReport') && !currentSdiMessages.hasOwnProperty('noIprReport')) ||
          (!reports.SDIMessages.hasOwnProperty('noIprReport') && currentSdiMessages.hasOwnProperty('noIprReport'))) {
          setSdiMessages(reports.SDIMessages);
        } else if (reports.SDIMessages.hasOwnProperty('noIprReport') && currentSdiMessages.hasOwnProperty('noIprReport')) {
          if (reports.SDIMessages.noIprReport.warningMsg !== currentSdiMessages.noIprReport.warningMsg) {
            setSdiMessages(reports.SDIMessages);
          }
        }
        if ((reports.SDIMessages.hasOwnProperty('noReport') && !currentSdiMessages.hasOwnProperty('noReport')) ||
           (!reports.SDIMessages.hasOwnProperty('noReport') && currentSdiMessages.hasOwnProperty('noReport'))) {
          setSdiMessages(reports.SDIMessages);
        } else if (reports.SDIMessages.hasOwnProperty('noReport') && currentSdiMessages.hasOwnProperty('noReport')) {
          if (reports.SDIMessages.noReport.warningMsg !== currentSdiMessages.noReport.warningMsg) {
            setSdiMessages(reports.SDIMessages);
          }
        }
      }
    }

    if (reports.Reports[0].role_cd === 1 || reports.Reports[0].role_cd === 2) {
      if (!reports.Courses.length) {
        setNotCourseAuthorized(true);
      } else {
        setNotCourseAuthorized(false);
        if (reports.Courses.length !== courses.length) {
          setCourses(reports.Courses);
          setGenesisSttVal(reports.sttValue2);
        } else {
          reports.Courses.forEach((course, i) => {
            let newCourse = course.subjectName;
            if (newCourse !== courses[i].subjectName) {
              setCourses(reports.Courses);
              setGenesisSttVal(reports.sttValue2);
            }
          });
        }
      }
    }
    setReportsData(reports.Reports);
  };

  const setMessagesState = messages => {
    let yearMessage = [];
    setGenMessages(messages.Items);
    messages.Items.forEach(message => {
      if (message.role_cd.includes(reportsData[0].role_cd) && !message.all_years) {
        yearMessage.push(message);
      }
    });
    let setMessageList = false;
    if (yearMessageList.length !== yearMessage.length) {
      setMessageList = true;
    } else {
      yearMessage.forEach((yearMessage, i) => {
        if (yearMessage.message !== yearMessageList[i].message) {
          setMessageList = true;
        }
      });
    }
    if (setMessageList) {
      setYearMessageList(yearMessage);
    }
  };

  return (
    <Fragment>
      <DashboardHeader
        selectedOrg={props.selectedOrg}
        setSelectedOrg={props.setSelectedOrg}
        searchedOrg={props.searchedOrg}
        setShowOrgSelection={props.setShowOrgSelection}
        setShowRegionalSearch={props.setShowRegionalSearch}
        setSearchedOrg={props.setSearchedOrg}
        selectedYear={selectedYear}
        setYearChanged={setYearChanged}
        setSelectedYear={setSelectedYear}
        setNotCourseAuthorized={setNotCourseAuthorized}
      />

      {isLoading ? (
        <Spinner />
      )
        : notCourseAuthorized ? (
          <div className = "course-auth-error-container">
            <NoCourseAuth selectedYear={selectedYear} />
          </div>
        )
          : hasErrorOnYearChange ? (
            <div><ShowError error={hasErrorOnYearChange} notShowPageTitle={true} /></div>
          ) : (
            <DashboardContent
              reportsData={reportsData}
              sdiMessages={sdiMessages}
              courses={courses}
              yearMessageList={yearMessageList}
              allYearsMessageList={allYearsMessageList}
              searchedOrg={props.searchedOrg}
              selectedYear={selectedYear}
              genesisSttVal={genesisSttVal}
              selectedOrgId={props.selectedOrg.orgId}
            />
          )}
    </Fragment>
  );
};

HomeSignedIn.propTypes = {
  reportsData: PropTypes.array,
  sdiMessages: PropTypes.object,
  courses: PropTypes.array,
  genMessages: PropTypes.array,
  genesisSttVal: PropTypes.string,
  searchedOrg: PropTypes.object,
  selectedOrg: PropTypes.object,
  setSelectedOrg: PropTypes.func,
  setShowOrgSelection: PropTypes.func,
  setShowRegionalSearch: PropTypes.func,
  setSearchedOrg: PropTypes.func
};

export default HomeSignedIn;
