import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from '@cb/apricot-react';
import DetailTable from '../../common/detailTable';
import { CULTURE, CREDIT, OPTIMIZATION } from '../constants';
import {CULTURE_TOOLTIP_DESC, CREDIT_TOOLTIP_DESC, OPTIMIZATION_TOOLTIP_DESC} from '../utils.js';

const ViewThresholdsModal = ({year, criteriaTableData, viewThresholdsModalTrigger}) => {
  return (<Modal title={'AP Honor Roll Metrics'} trigger={viewThresholdsModalTrigger} withFooter={false} modalId='viewThresholdModal'>
    To qualify for the {year} AP School Honor Roll, schools must meet or exceed the criteria for a given recognition level for all three metrics. Below are the thresholds for each criteria and recognition level.
    <div className='cb-margin-top-16'>
      <DetailTable tableId='honor_roll_DA_criteria_table' responsive={true} data={criteriaTableData} columns={[{
        title: 'AP School Honor Roll Metrics',
        field: 'category',
        rowHeader: true
      },
      { title: 'Criteria|Bronze', field: 'bronze', className: 'cb-align-center bronzeHeader'},
      { title: 'Criteria|Silver', field: 'silver', className: 'cb-align-center silverHeader'},
      { title: 'Criteria|Gold', field: 'gold', className: 'cb-align-center goldHeader'},
      { title: 'Criteria|Platinum', field: 'platinum', className: 'cb-align-center platinumHeader'}
      ]} />
    </div>
    <br />
    <div><b>{CULTURE}:</b> {CULTURE_TOOLTIP_DESC}</div>
    <br />
    <div><b>{CREDIT}:</b> {CREDIT_TOOLTIP_DESC}</div>
    <br />
    <div><b>{OPTIMIZATION}:</b> {OPTIMIZATION_TOOLTIP_DESC}</div>
  </Modal>);
};

ViewThresholdsModal.propTypes = {
  year: PropTypes.number,
  criteriaTableData: PropTypes.array,
  viewThresholdsModalTrigger: PropTypes.string
};

export default ViewThresholdsModal;
