import { Modal } from '@cb/apricot-react';
import propTypes from 'prop-types';
import React, { useContext, useRef, useState } from 'react';
import pdfImage from '../../common/downloads/pdf-icon.png';
import pngImage from '../../common/downloads/png-icon.png';
import docxImage from '../../common/downloads/docx-icon.png';
import DownloadRow from '../../common/downloads/downloadRow';
import { logDownload } from '../../common/downloads/downloadModal';
import getHonorRollLetter from '../../../../api/honorRollLetter';
import { AppContext } from '../../../../AppContext';
import getHonorRollCertificate from '../../../../api/honorRollCertificate';
import FbImageGenerator from './imageGeneration/fbImageGenerator';
import IgImageGenerator from './imageGeneration/igImageGenerator';
import { AP_ACCESS_BADGE_NAME } from '../constants';
import './imageGeneration/fonts.css';
import { PRESS_RELEASE_URL, HONOR_ROLL_PRESS_RELEASE_FILENAME } from '../utils';

const PNG = 'PNG';
const PDF = 'PDF';
const DOCX = 'DOCX';

const LETTER_AUDIT_TITLE = 'HonorRollLetter';
const LETTER_ACCESS_AUDIT_TITLE = 'HonorRollLetterAccess';
const CERTIFICATE_AUDIT_TITLE = 'HonorRollCertificate';
const CERTIFICATE_ACCESS_AUDIT_TITLE = 'HonorRollCertificate';
const FB_AUDIT_TITLE = 'HonorRollFb';
const FB_ACCESS_AUDIT_TITLE = 'HonorRollFbAccess';
const IG_AUDIT_TITLE = 'HonorRollIg';
const IG_ACCESS_AUDIT_TITLE = 'HonorRollIgAccess';
const TEMPLATE_AUDIT_TITLE = 'HonorRollTemplate';

// file names
const getLetterFilename = ({year, withExtension, accessAward}) => `AP_School_Honor_Roll${accessAward ? '_Access_Award' : ''}_Letter_${year}${withExtension ? '.pdf' : ''}`;
const getCertificateFilename = ({year, tier, withExtension, accessAward}) => `${tier}_AP_Honor_Roll${accessAward ? '_Access_Award' : ''}_Certificate_${year}${withExtension ? '.pdf' : ''}`;
const getPngFilename = ({year, badgeName, withExtension, socialId}) => `${badgeName}_Badge_${socialId}_${year}${withExtension ? '.png' : ''}`;

// button Ids
const CERTIFICATE_ID = 'dlHonorRollCertificate';
const CERTIFICATE_ACCESS_ID = 'dlHonorRollCertificateAccess';
const LETTER_ID = 'dlHonorRollLetter';
const LETTER_ACCESS_ID = 'dlHonorRollLetterAccess';
const FB_ID = 'dlHonorRollFb';
const FB_ACCESS_ID = 'dlHonorRollFbAccess';
const IG_ID = 'dlHonorRollIg';
const IG_ACCESS_ID = 'dlHonorRollIgAccess';
const TEMPLATE_ID = 'dlHonorRollTemplate';

const getImage = type => {
  switch (type) {
    case PNG:
      return pngImage;
    case DOCX:
      return docxImage;
    default:
      return pdfImage;
  }
};

const getName = (fn, ln) => `${fn ? fn + ' ' : ''}${ln ?? ''}`;

export const downloadCelebrationLetter = ({accessAward, setFileData, name, year, schoolAddress}) => async () => {
  const res = await getHonorRollLetter({name, year, schoolAddress, accessAward});
  if (res.data) {
    setFileData({
      href: 'data:application/pdf;base64,' + res.data,
      download: getLetterFilename({year, accessAward})
    });
  }
};

const downloadCertificate = ({accessAward, setFileData, year, schoolName, tier}) => async () => {
  const res = await getHonorRollCertificate({year, tier, schoolName, accessAward});
  if (res.data) {
    setFileData({
      href: 'data:application/pdf;base64,' + res.data,
      download: getCertificateFilename({tier, year, accessAward})
    });
  }
};

const exportCanvasAsPNG = (canvasRefToExport, filename) => async () => {
  let canvasElement = canvasRefToExport.current;
  let MIME_TYPE = 'image/png';
  let imgURL = canvasElement.toDataURL(MIME_TYPE);
  let dlLink = document.createElement('a');
  dlLink.download = filename;
  dlLink.href = imgURL;
  dlLink.dataset.downloadurl = [MIME_TYPE, dlLink.download, dlLink.href].join(':');

  document.body.appendChild(dlLink);
  dlLink.click();
  document.body.removeChild(dlLink);
};

const getDownloadRowObj = ({text, filename, buttonId, description, type, downloadFile}) => ({
  filename,
  buttonId,
  description,
  buttonTitle: `Download ${text}`,
  imageSrc: getImage(type),
  text,
  downloadFile
});

const CelebrationKitModal = ({
  org,
  sttVal,
  year,
  trigger,
  isAggregate,
  isDistrictAdmin,
  tier,
  accessAward,
  setFileData,
  schoolName,
  schoolAddress
}) => {

  const appContext = useContext(AppContext);
  const [loadingMap, setLoadingMap] = useState({});
  const fbCanvasRef = useRef();
  const igCanvasRef = useRef();
  const fbAccessCanvasRef = useRef();
  const igAccessCanvasRef = useRef();

  const updateLoadingMap = (id, isLoading) => {
    setLoadingMap(previousState => {
      return { ...previousState, [id]: !!isLoading };
    });
  };

  // makes API call for reporting audit log entry after report is generated
  const auditWrapper = async (reportGenerationFunc, type, name) => {
    try {
      let startTime = Date.now();
      await reportGenerationFunc();
      let endTime = Date.now();
      logDownload({
        sttVal,
        name,
        type,
        year,
        startTime,
        endTime,
        orgId: org,
        isAggregate,
        isDistrictAdmin
      });
    } catch (err) {
      console.log('err', err);
    }

  };

  const downloadsData = [
    getDownloadRowObj({
      text: `${tier} AP School Honor Roll Certificate PDF`,
      filename: getCertificateFilename({tier, year, accessAward, withExtension: true}),
      buttonId: CERTIFICATE_ID,
      description: 'Share with your administration!',
      type: PDF,
      downloadFile: async () => {
        updateLoadingMap(CERTIFICATE_ID, true);
        await auditWrapper(downloadCertificate({setFileData, tier, year, schoolName}), 'PDF', CERTIFICATE_AUDIT_TITLE);
        updateLoadingMap(CERTIFICATE_ID, false);
      }
    }),
    getDownloadRowObj({
      text: 'AP School Honor Roll Congratulatory Letter PDF',
      filename: getLetterFilename({year, withExtension: true}),
      buttonId: LETTER_ID,
      description: 'Share with your administration!',
      type: PDF,
      downloadFile: async () => {
        updateLoadingMap(LETTER_ID, true);
        await auditWrapper(downloadCelebrationLetter({setFileData, name: getName(appContext.firstName, appContext.lastName), year, schoolAddress}), 'PDF', LETTER_AUDIT_TITLE);
        updateLoadingMap(LETTER_ID, false);
      }
    }),

    getDownloadRowObj({
      text: `${tier} AP School Honor Roll Badge - Facebook PNG`,
      filename: getPngFilename({year, badgeName: tier, withExtension: true, socialId: 'FB'}),
      buttonId: FB_ID,
      description: 'Share on social media!',
      type: PNG,
      downloadFile: async () => {
        updateLoadingMap(FB_ID, true);
        await auditWrapper(exportCanvasAsPNG(fbCanvasRef, getPngFilename({year, badgeName: tier, socialId: 'FB'})), 'PNG', FB_AUDIT_TITLE);
        updateLoadingMap(FB_ID, false);
      }
    }),
    getDownloadRowObj({
      text: `${tier} AP School Honor Roll Badge - Instagram PNG`,
      filename: getPngFilename({year, badgeName: tier, withExtension: true, socialId: 'IG'}),
      buttonId: IG_ID,
      description: 'Share on social media!',
      type: PNG,
      downloadFile: async () => {
        updateLoadingMap(IG_ID, true);
        await auditWrapper(exportCanvasAsPNG(igCanvasRef, getPngFilename({year, badgeName: tier, socialId: 'IG'})), 'PNG', IG_AUDIT_TITLE);
        updateLoadingMap(IG_ID, false);
      }
    }),
    getDownloadRowObj({
      text: `AP School Honor Roll Press Release Template DOCX`,
      filename: HONOR_ROLL_PRESS_RELEASE_FILENAME,
      buttonId: TEMPLATE_ID,
      description: 'Share on social media!',
      type: DOCX,
      downloadFile: async () => {
        updateLoadingMap(TEMPLATE_ID, true);
        await auditWrapper(() => {
          const a = document.createElement('a');
          a.href = PRESS_RELEASE_URL;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }, 'DOCX', TEMPLATE_AUDIT_TITLE);
        updateLoadingMap(TEMPLATE_ID, false);
      }
    })
  ];

  if (accessAward) {
    downloadsData.splice(1, 0,
      getDownloadRowObj({
        text: `${tier} AP School Honor Roll and ${AP_ACCESS_BADGE_NAME} Award Certificate PDF`,
        filename: 'todo.pdf',
        buttonId: CERTIFICATE_ACCESS_ID,
        description: 'Share with your administration!',
        type: PDF,
        downloadFile: async () => {
          updateLoadingMap(CERTIFICATE_ACCESS_ID, true);
          await auditWrapper(downloadCertificate({setFileData, tier, year, schoolName, accessAward: true}), 'PDF', CERTIFICATE_ACCESS_AUDIT_TITLE);
          updateLoadingMap(CERTIFICATE_ACCESS_ID, false);
        }
      }));

    // this one REPLACES existing congratulatory letter, other access award downloads are additional
    downloadsData.splice(2, 1,
      getDownloadRowObj({
        text: `AP School Honor Roll and ${AP_ACCESS_BADGE_NAME} Award Congratulatory Letter PDF`,
        filename: getLetterFilename({year, accessAward: true, withExtension: true}),
        buttonId: LETTER_ACCESS_ID,
        description: 'Share with your administration!',
        type: PDF,
        downloadFile: async () => {
          updateLoadingMap(LETTER_ACCESS_ID, true);
          await auditWrapper(downloadCelebrationLetter({setFileData, name: getName(appContext.firstName, appContext.lastName), year, schoolAddress, accessAward: 1}), 'PDF', LETTER_ACCESS_AUDIT_TITLE);
          updateLoadingMap(LETTER_ACCESS_ID, false);
        }
      }));

    downloadsData.splice(5, 0,
      getDownloadRowObj({
        text: `${AP_ACCESS_BADGE_NAME} Award Badge - Facebook PNG`,
        filename: getPngFilename({year, badgeName: 'AP_Access', withExtension: true, socialId: 'FB'}),
        buttonId: FB_ACCESS_ID,
        description: 'Share on social media!',
        type: PNG,
        downloadFile: async () => {
          updateLoadingMap(FB_ACCESS_ID, true);
          await auditWrapper(exportCanvasAsPNG(fbAccessCanvasRef, getPngFilename({year, badgeName: 'AP_Access', socialId: 'FB'})), 'PNG', FB_ACCESS_AUDIT_TITLE);
          updateLoadingMap(FB_ACCESS_ID, false);
        }
      }));

    downloadsData.splice(6, 0,
      getDownloadRowObj({
        text: `${AP_ACCESS_BADGE_NAME} Award Badge - Instagram PNG`,
        filename: getPngFilename({year, badgeName: AP_ACCESS_BADGE_NAME, withExtension: true, socialId: 'IG'}),
        buttonId: IG_ACCESS_ID,
        description: 'Share on social media!',
        type: PNG,
        downloadFile: async () => {
          updateLoadingMap(IG_ACCESS_ID, true);
          await auditWrapper(exportCanvasAsPNG(igAccessCanvasRef, getPngFilename({year, badgeName: 'AP_Access', socialId: 'IG'})), 'PNG', IG_ACCESS_AUDIT_TITLE);
          updateLoadingMap(IG_ACCESS_ID, false);
        }
      }));
  }

  return (
    <>
      { /* Pre-load font for PNG download */ }
      <div>
        <span style={{visibility: 'hidden', fontSize: '1px', fontFamily: 'LexiaRegular'}}>pre-load font</span>
      </div>
      <Modal title={`AP School Honor Roll Celebration Kit ${year}`} trigger={trigger} withFooter={false}>
        <div className="container">
          <div className="row">
            <h3 className="cb-font-weight-bold cb-sans-serif cb-font-size-regular">
            Available for download:
            </h3>
          </div>
          <div className="row">
            <br />
          </div>

          {
            downloadsData.map((i, index) => (<DownloadRow
              key={`celebrationRowKey${index}`}
              filename={i.filename}
              description={i.description}
              buttonId={i.buttonId}
              buttonTitle={i.buttonTitle}
              imageSrc={i.imageSrc}
              text={i.text}
              downloadFile={i.downloadFile}
              isLoading={loadingMap[i.buttonId]}
              noStyling={true}
            />))
          }

          <div className='cb-font-size-small cb-margin-top-8'>If printing PDFs, it is recommended to print in color.</div>
        </div>
      </Modal>
      <FbImageGenerator canvasRef={fbCanvasRef} year={year} badge={tier} />
      <IgImageGenerator canvasRef={igCanvasRef} year={year} badge={tier} />

      {
        accessAward &&
        <>
          <FbImageGenerator canvasRef={fbAccessCanvasRef} year={year} badge={AP_ACCESS_BADGE_NAME} />
          <IgImageGenerator canvasRef={igAccessCanvasRef} year={year} badge={AP_ACCESS_BADGE_NAME} />
        </>
      }

    </>

  );
};

CelebrationKitModal.propTypes = {
  org: propTypes.number,
  sttVal: propTypes.string,
  year: propTypes.number,
  children: propTypes.instanceOf(Object),
  trigger: propTypes.string,
  isAggregate: propTypes.bool,
  isDistrictAdmin: propTypes.bool,
  tier: propTypes.string,
  accessAward: propTypes.bool,
  setFileData: propTypes.func,
  schoolName: propTypes.string,
  schoolAddress: propTypes.string
};

export default CelebrationKitModal;
