import PropTypes from 'prop-types';
import React, {useState} from 'react';
import DetailTable from '../../common/detailTable.js';
import { Pager } from '@cb/apricot-react';
import { tabCheck } from '../../common/utils.js';
import { waitMs } from '../../../../utils/tableUtils.js';

export const sortHonorRollTableData = (data, field, ascending) => {
  const fieldMap = {
    schoolInfo: 'schoolNameAICode',
    overall_tier: 'overall_tier',
    access_award: 'access_award',
    college_culture_pct: 'college_culture_pct_num',
    college_credit_pct: 'college_credit_pct_num',
    college_optimization_pct: 'college_optimization_pct_num'
  };
  const fieldType = typeof (data[0]?.[fieldMap[field]]);

  if (ascending) {
    if (fieldType === 'string') {
      return data.sort((a, b) => a[fieldMap[field]].localeCompare(b[fieldMap[field]]));
    } else {
      return data.sort((a, b) => a[fieldMap[field]] - b[fieldMap[field]]);
    }
  } else {
    if (fieldType === 'string') {
      return data.sort((a, b) => b[fieldMap[field]].localeCompare(a[fieldMap[field]]));
    } else {
      return data.sort((a, b) => b[fieldMap[field]] - a[fieldMap[field]]);
    }
  }
};

const HonorRollDATable = ({tableData, columnData}) => {

  const PAGE_SIZE = 20;
  const [currentHRPage, setCurrentHRPage] = useState(0);
  const [schoolTableData, setSchoolTableData] = useState(tableData);
  const [selectedSort, setSelectedSort] = useState({field: 'schoolNameAICode'});
  const hrPageCount = schoolTableData.length > 0 ? Math.ceil(schoolTableData.length / PAGE_SIZE) : 0;
  const paginatedHRData = schoolTableData.slice(currentHRPage * PAGE_SIZE, currentHRPage * PAGE_SIZE + PAGE_SIZE);

  return (<>
    <div className='cb-margin-bottom-16' style={{ maxHeight: '600px', overflowY: 'auto' }}>
      <DetailTable
        columns={columnData}
        data={paginatedHRData}
        sortType={tableData.length > 1 ? 'inline' : 'none'}
        stickyHeaderParent={true}
        maxHeight={600}
        overflowX={false}
        tabIndex={tabCheck}
        a11yHighlightText=''
        tableId="honor_roll_da_table"
        sortFn={(data, sort) => {
        // we handle the sort in onSort
          return data;
        }}
        onSort={async (field, ascending) => {
          await waitMs(500);
          setSchoolTableData(sortHonorRollTableData(schoolTableData, field, ascending));
          setSelectedSort({field, ascending});
          setCurrentHRPage(0);
        }}
      />
    </div>

    {schoolTableData.length > PAGE_SIZE && <Pager
      current={currentHRPage + 1}
      max={hrPageCount}
      onPageChange={page => {
        setCurrentHRPage(page - 1);
      }}
    />}
  </>);
};

HonorRollDATable.propTypes = {
  tableData: PropTypes.array,
  columnData: PropTypes.array
};

export default HonorRollDATable;
